<template>
  <v-row>
    <v-dialog v-model="show" persistent max-width="500">
      <v-card>
        <v-card-text class="pa-0">
          <v-list class=" pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title style="font-size:large; line-height:2.2;">
                  {{ $t("settingdefaultbusiness") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="35" dense dark :color="color.theme"
                  >business</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-flex lg11 md11 sm11 xs11>
          <v-list>
            <v-list-item>
              <v-list-item-icon
                ><v-icon class="pt-1" :color="color.theme"
                  >business</v-icon
                ></v-list-item-icon
              >
              <v-list-item-content v-if="lang_ === 'th'">
                <!-- {{listdropdown}} -->
                <v-autocomplete
                  v-model="Business_default"
                  :items="listdropdown"
                  item-text="first_name_th"
                  item-value="business_id"
                  dense
                  outlined
                ></v-autocomplete>
                  <!-- :label="datalogindefault.first_name_th === 'citizen' ? 'ผู้ใช้งานทั่วไป' : datalogindefault.first_name_th" -->
                  <!-- @change="fn_settingdefaultbusiness" -->
                <div
                  class="d-flex"
                >
                  <div style="justify-content: center;">
                    <p style="font-size:12px;">
                      <v-icon class="mb-1" size="16">mdi-information</v-icon>
                      <b>
                        <!-- สามารถเปลี่ยนการตั้งค่าบริษัทเริ่มต้นได้ -->
                        กรณีที่ต้องการเปลี่ยน "การตั้งค่าบริษัทเริ่มต้น"
                      </b>
                    </p>
                    <p style="font-size:12px;">
                        <!-- (โปรไฟล์ → ข้อมูลส่วนตัว → ตั้งค่าบริษัทเริ่มต้น) -->
                        1. เลือกที่ <b>"รูปโปรไฟล์"</b> > เลือก <b>"ข้อมูลส่วนตัว"</b>
                    </p>
                    <p style="font-size:12px;">
                        2. เลื่อนไปที่ <b>"ตั้งค่าบริษัทเริ่มต้น"</b> > เลือก <b>"บริษัทที่ต้องการตั้งค่าเริ่มต้น"</b>
                    </p>
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-autocomplete
                  v-model="Business_default"
                  :items="listdropdown"
                  item-text="first_name_eng"
                  item-value="business_id"
                  dense
                  outlined
                ></v-autocomplete>
                  <!-- :label="datalogindefault.first_name_eng === 'citizen' ? 'Personal' : datalogindefault.first_name_eng" -->
                  <!-- @change="fn_settingdefaultbusiness" -->
                <div
                  class="d-flex"
                >
                  <div style="justify-content: center;">
                    <p style="font-size:12px;">
                    <v-icon class="mb-1" size="16">mdi-information</v-icon>
                      <b>
                        <!-- กรณีที่ต้องการเปลี่ยน การตั้งค่า -->
                        In case you want to change "Setting default Business"
                      </b>
                    </p>
                    <p style="font-size:12px;">
                        1. Select <b>"Profile Picture"</b> > Select <b>"Profile"</b>
                    </p>
                    <p style="font-size:12px;">
                        2. Scroll to <b>"Setting default Business"</b> > Select <b>"Business that needs default settings"</b>
                    </p>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="white--text"
            color="red"
            @click="$emit('close')"
          >
            {{ $t("admin.close") }}
          </v-btn>
          <v-btn
            class="white--text"
            :color="color.theme"
            :loading="loadprogress"
            @click="setting()"
          >
            {{ $t("admin.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  components: {},
  props: ["show"],
  data() {
    return {
      lang_: "",
      Business_default: "",
      listdropdown: [],
      datalogindefault: [],
      id_biz: "",
      loadprogress: false,
      check_btn: false,
      citizen_info: {},
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataOneAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("show", this.show);
        }
        return this.show;
      },
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    title() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    textfield() {
      return "min-height: 1px;";
    },
    success() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 500;
      } else {
        return 346;
      }
    },
  },
  methods: {
    setting(){
      this.fn_settingdefaultbusiness();
      this.get_profile();
    },
    // ส่งค่า payload เพื่อไปยิง api gotobiz_business เพื่อไปหน้าไดร์ฟบริษัทที่เลือก
    goto_biz(payload) {
      // กรณีเลือก ผู้ใช้งานทั่วไป
      if (this.id_biz === "") {
        console.log("gotobiz_citizen", this.id_biz);
        // เปลี่ยนค่า setting_select_biz (หน้า myfiles-scrolling-new) ให้เท่ากับ false เพื่อปิดการเปิด dialog ตั้งค่าบริษัทเริ่มต้น
         this.$store.state.setting_select_biz = false;
        this.$emit("close");
      } 
      // กรณีเลือก บริษัท
      else {
        console.log("gotobiz_business", this.id_biz);
        this.$store.dispatch("switchaccount_business", payload).then((msg) => {
          if (msg.status === "Business Access Granted") {
            console.log("ok");
            this.$router.push({
              name: "directory",
              params: { id: this.dataAccountActive.business_info.my_folder_id },
            });
          }
        });
        // เปลี่ยนค่า setting_select_biz (หน้า myfiles-scrolling-new) ให้เท่ากับ false เพื่อปิดการเปิด dialog ตั้งค่าบริษัทเริ่มต้น
         this.$store.state.setting_select_biz = false;
        this.$emit("close");
      }
    },
    // api เลือกบริษัทเริ่มต้น
    async fn_settingdefaultbusiness(item) {
      console.log("item", item);
      var payload = {
        business_id: this.Business_default,
        type: "account_id",
        data_id: this.dataCitizenProfile.account_id,
      };
      console.log(" fn_settingdefaultbusiness payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/update_login_defualt",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.loadprogress = false;

            this.check_btn = true;
            this.fn_getlistbusiness();
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // api get ข้อมูลบริษัท
    fn_getlistbusiness() {
      (this.listdropdown = []), (this.dropdown = []);
      this.datalogindefault = [];
      var payload = {
        user_id: this.dataCitizenProfile.user_id,
      };
      console.log("payload", payload);

      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/get_login_default_detail",
          payload
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.datalogindefault = response.data.login_default;
            this.id_biz = response.data.login_default.login_default;
            console.log("id_biz", this.id_biz);
            console.log("dataAccountId", this.dataAccountId);
            //  ผู้ใช้งานทั่วไป
            this.citizen_info["account_id"] = this.dataAccountId;
            this.citizen_info["branch_no"] = null;
            this.citizen_info["business_id"] = "";
            this.citizen_info["first_name_eng"] = "Personal";
            this.citizen_info["first_name_th"] = "ผู้ใช้งานทั่วไป";
            this.listdropdown.push(this.citizen_info);
            //  บริษัทอื่น
            for (let i = 0; i < response.data.business_dropdown.length; i++) {
              if (
                response.data.business_dropdown[i]["first_name_eng"] !==
                "citizen"
              ) {
                let datadropdown = {};
                datadropdown["account_id"] =
                  response.data.business_dropdown[i]["account_id"];
                datadropdown["branch_no"] =
                  response.data.business_dropdown[i]["branch_no"];
                datadropdown["business_id"] =
                  response.data.business_dropdown[i]["business_id"] !== ""
                    ? response.data.business_dropdown[i]["business_id"]
                    : " ";
                datadropdown["first_name_eng"] =
                  response.data.business_dropdown[i]["first_name_eng"];
                datadropdown["first_name_th"] =
                  response.data.business_dropdown[i]["first_name_th"] ==
                  "citizen"
                    ? "ผู้ใช้งานทั่วไป"
                    : response.data.business_dropdown[i]["first_name_th"];
                this.listdropdown.push(datadropdown);
              }
            }
            this.loadprogress = false;
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // ยิง api getprofile เพื่อ get ค่าข้อมูล
    async get_profile() {
      if (this.check_btn === false) {
        await this.fn_settingdefaultbusiness(this.citizen_info);
      }
      this.loadprogress = true;
      let authorize;
      if (sessionStorage.getItem("checklogin") == "false") {
        if (sessionStorage.getItem("token") == "null") {
          let user_id = CryptoJS.AES.decrypt(
            sessionStorage.getItem("user_id"),
            CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
            { mode: CryptoJS.mode.ECB }
          );
          user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8));
          // authorize = { user_id: sessionStorage.getItem("user_id") };
          authorize = { user_id: user_id };
          // let biz_user_id = sessionStorage.getItem("biz_active");
          // authorize = { user_id:  biz_user_id.user_id };
        } else {
          // authorize = { accesstoken: VueCookies.get("token") };
          authorize = {
            accesstoken: window.atob(sessionStorage.getItem("token")),
          };
        }
      } else {
        if (VueCookies.get("token") === "" || VueCookies.get("token") == null) {
          let user_id = CryptoJS.AES.decrypt(
            VueCookies.get("user_id"),
            // localStorage.getItem("user_id"),
            CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
            { mode: CryptoJS.mode.ECB }
          );
          user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8));
          authorize = { user_id: user_id };
          // let biz_user_id = sessionStorage.getItem("biz_active");
          // authorize = { user_id:  biz_user_id.user_id };
        } else {
          // authorize = { accesstoken: VueCookies.get("token") };
          authorize = { accesstoken: window.atob(VueCookies.get("token")) };
          // authorize = { accesstoken: sessionStorage.getItem("token") };
        }
      }
      // เอาข้อมูล ใน api getprofile มาใส่ใน payload เพื่อส่งค่า payload ไปยัง goto_biz เพื่อไปยิง login_business
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getprofile",
        authorize
      );
      console.log("result", result);
      let payload = {
        taxid: result.data.result.login_default.id_card_num,
        accesstoken: result.data.result.accesstoken,
        biz_id: result.data.result.login_default.id,
        branch_no: result.data.result.login_default.branch_no || "00000",
      };
      console.log("payload", payload);
      Toast.fire({
        icon: "success",
        title: this.$t("settinfdefaulesuccess"),
      });
      this.loadprogress = false;
      this.goto_biz(payload);
    },
  },
  mounted() {
    this.fn_getlistbusiness();
    this.lang_ = localStorage.getItem("lang");
  },
};
</script>
